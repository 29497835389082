.bg-container-color{
    background: rgba(255, 255, 255, 0.5);
}
.bg-signup{
    background: #36447B;
    transition: 1s;
    border-radius: 10px;
}
.bg-signup:hover{
    background: #36447e;
}
.btn-color{
    background: white;
    border-radius: 50px;
    padding: 10px 30px;
    border: 2px solid #fff;
    letter-spacing: 2px;
    color: black;
    transition: 0.9s;
}
.btn-color:hover{
    background: #36447c;
    color: white;
}
