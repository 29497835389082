/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#heroImg {
    width: 100%;
    height: 70vh;
    background: url("../images/lab-3.jpg") top center;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  #heroImg:before {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #heroOne {
    width: 100%;
    height: 70vh;
    background: url("../images/lab-21.jpg") top center;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  #heroOne:before {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #heroTwo {
    width: 100%;
    height: 70vh;
    background: url("../images/lab-new-1.png") top center;
    background-size: cover;
    background-position: center;
    position: relative;
  }
  #heroTwo:before {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  #hero .container {
    padding-top: 72px;
  }
  @media (max-width: 992px) {
    #hero .container {
      padding-top: 62px;
    }
  }
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  #hero h2 {
    color: #eee;
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    border: 2px solid #fff;
    color: #fff;
  }
  #hero .btn-get-started:hover {
    background: #36447e;
    border: 2px solid #36447e;
  }
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  @media (max-width: 768px) {
    #hero {
      height: 70vh;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
  



   /* #hero {
    width: 100%;
    height: 80vh;
    background: url("../images/lab-1.jpg") top center;
    background-size: cover;
    position: relative;
  }
  #hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #hero .container {
    padding-top: 72px;
  }
  @media (max-width: 992px) {
    #hero .container {
      padding-top: 62px;
    }
  }
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    font-family: "Poppins", sans-serif;
  }
  #hero h2 {
    color: #eee;
    margin: 10px 0 0 0;
    font-size: 24px;
  }
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 50px;
    transition: 0.5s;
    margin-top: 30px;
    border: 2px solid #fff;
    color: #fff;
  }
  #hero .btn-get-started:hover {
    background: #36447e;
    border: 2px solid #36447e;
  }
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  @media (max-width: 768px) {
    #hero {
      height: 100vh;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
   */