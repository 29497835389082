  /**
  * Get Startet Button 
  */
  /* #navbar-color {
    background: rgba(13, 255, 5, 0.99) !important;
  } */
  .get-started-btn {
    margin-left: 22px;
    background: #36447B;
    color: #fff;
    border-radius: 50px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
  }
  .get-started-btn:hover {
    background: #36447B;
    color: #fff;
  }
  @media (max-width: 768px) {
    .get-started-btn {
      margin: 0 15px 0 0;
      padding: 6px 18px;
    }
  }
  /* .navbar-brand{
    color: #36447B !important;
    font-size: 1rem;
  } */
  /* .navbar {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09) !important;
  } */
  .show-location-link {
    color: black !important;
  }
  .show-location-link-active{
    color: #36447B !important;
  }
