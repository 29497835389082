/*--------------------------------------------------------------
# Courses
--------------------------------------------------------------*/
.courses .course-item {
    border-radius: 5px;
    border: 1px solid #eef0ef;
  }
  .courses .course-content {
    padding: 15px;
  }
  .courses .course-content h3 {
    font-weight: 700;
    font-size: 20px;
  }
  .courses .course-content h3 a {
    color: #37423b;
    transition: 0.3s;
  }
  .courses .course-content h3 a:hover {
    color: #36447B;
  }
  .courses .course-content p {
    font-size: 14px;
    color: #777777;
  }
  .courses .course-content h4 {
    font-size: 14px;
    background: #36447B;
    padding: 7px 14px;
    color: #fff;
    margin: 0;
  }
  .courses .course-content .price {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    color: #37423b;
  }
  .courses .trainer {
    padding-top: 15px;
    border-top: 1px solid #eef0ef;
  }
  .courses .trainer .trainer-profile img {
    max-width: 50px;
    border-radius: 50px;
  }
  .courses .trainer .trainer-profile span {
    padding-left: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #5a6c60;
  }
  .courses .trainer .trainer-rank {
    font-size: 18px;
    color: #657a6d;
  }
  