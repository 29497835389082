/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
    padding: 30px 0;
  }
  .counts .counters span {
    font-size: 48px;
    display: block;
    color: #36447B;
    font-weight: 700;
  }
  .counts .counters p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #37423b;
  }
  