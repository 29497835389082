.gallery-img{
    overflow: hidden;
    border-radius: 20px;
  }
  .zoom-in-img img {
    display: block;
    border-radius: 20px;
    transform: scale(1);
    transition: 0.4s ease-in-out;
  }
  .zoom-in-img:hover img {
    transform: scale(1.5);
    
  }
  